import { navigationConfig } from '@hubcms/brand';
import { useNavigation } from '@hubcms/data-access-navigation';
import { Button, IconButton } from '@mediahuis/chameleon-react';
import { Close } from '@mediahuis/chameleon-theme-wl/icons';

import { NavigationIcon } from '../NavigationIcon';

type NavigationMenuProps = {
  size?: 'sm' | 'lg';
};

function NavigationMenu({ size = 'lg' }: NavigationMenuProps) {
  const { isAsideMenuOpen, toggleIsAsideMenuOpen } = useNavigation();

  if (size === 'sm') {
    return (
      <IconButton
        aria-label={navigationConfig.menuLabel}
        data-testid="navigation-menu"
        icon={isAsideMenuOpen ? Close : <NavigationIcon name="menu-services" />}
        size="sm"
        onClick={toggleIsAsideMenuOpen}
      />
    );
  }

  return (
    <Button
      data-testid="navigation-menu"
      iconLeft={isAsideMenuOpen ? Close : <NavigationIcon name="menu-services" />}
      size="sm"
      onClick={toggleIsAsideMenuOpen}
    >
      {isAsideMenuOpen ? navigationConfig.menuCloseLabel : navigationConfig.menuLabel}
    </Button>
  );
}

export default NavigationMenu;
