import type { NavItem } from '@hubcms/domain-navigation';
import type { ReactElement } from 'react';

import HeaderDesktop from './HeaderDesktop';
import HeaderMobile from './HeaderMobile';

type HeaderProps = {
  salesElement?: ReactElement;
  serviceItems: NavItem[];
  subscriptionItem?: NavItem;
  topicItems: NavItem[];
  topicLogoPosition?: 'center' | 'left';
  userMenuItems: NavItem[];
};

function Header({ salesElement, serviceItems, subscriptionItem, topicItems, topicLogoPosition, userMenuItems }: HeaderProps) {
  return (
    <header>
      <HeaderDesktop
        logoPosition={topicLogoPosition}
        salesElement={salesElement}
        serviceItems={serviceItems}
        subscriptionItem={subscriptionItem}
        topicItems={topicItems}
        userMenuItems={userMenuItems}
      />
      <HeaderMobile subscriptionItem={subscriptionItem} userMenuItems={userMenuItems} />
    </header>
  );
}

export default Header;
