import type { PropsWithChildren } from 'react';

import styles from './navigation-button.module.scss';

type NavigationButtonProps = {
  onClick?: () => void;
};

function NavigationButton({ children, onClick }: PropsWithChildren<NavigationButtonProps>) {
  return (
    <button className={styles.navigationButton} data-testid="navigation-button" onClick={onClick}>
      {children}
    </button>
  );
}

export default NavigationButton;
