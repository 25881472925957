function NavigationIconMenuServices() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Menu">
        <g id="Vector">
          <path
            d="M0.5 3C0.5 2.72386 0.723858 2.5 1 2.5H14.5C14.7761 2.5 15 2.72386 15 3C15 3.27614 14.7761 3.5 14.5 3.5H1C0.723858 3.5 0.5 3.27614 0.5 3Z"
            fill="black"
          />
          <path
            d="M1 8C1 7.72386 1.22386 7.5 1.5 7.5H5C5.27614 7.5 5.5 7.72386 5.5 8C5.5 8.27614 5.27614 8.5 5 8.5H1.5C1.22386 8.5 1 8.27614 1 8Z"
            fill="black"
          />
          <path
            d="M1 13C1 12.7239 1.22386 12.5 1.5 12.5H9.5C9.77614 12.5 10 12.7239 10 13C10 13.2761 9.77614 13.5 9.5 13.5H1.5C1.22386 13.5 1 13.2761 1 13Z"
            fill="black"
          />
        </g>
        <path
          id="Ellipse 1"
          d="M12.5 8C12.5 9.38071 11.3807 10.5 10 10.5C8.61929 10.5 7.5 9.38071 7.5 8C7.5 6.61929 8.61929 5.5 10 5.5C11.3807 5.5 12.5 6.61929 12.5 8Z"
          stroke="black"
        />
        <path
          id="Line 1"
          d="M14 13C14.1952 13.1953 14.5118 13.1953 14.7071 13C14.9023 12.8048 14.9023 12.4882 14.7071 12.2929L14 13ZM11 10L14 13L14.7071 12.2929L11.7071 9.29293L11 10Z"
          fill="black"
        />
      </g>
    </svg>
  );
}

export default NavigationIconMenuServices;
