import type { Event, GTMEvent, EventConditions } from '@hubcms/domain-gtm';
import { Deferred } from '@hubcms/utils-promise';

type EventWithConditions = Omit<Event, 'conditions'> & {
  conditions: EventConditions;
  resolve: () => void;
};

const queuedEvents: EventWithConditions[] = [];

function isEventWithConditions(event: Event | EventWithConditions): event is EventWithConditions {
  return 'conditions' in event;
}

export function addToQueue(event: EventWithConditions) {
  const { resolve, promise } = new Deferred<void>();
  queuedEvents.push({
    ...event,
    resolve,
  });
  return promise;
}

export function shouldEventBeQueued(sentEvents: GTMEvent[], event: Event | EventWithConditions): event is EventWithConditions {
  if (isEventWithConditions(event)) {
    return sentEvents.every(gtmEvent => gtmEvent.event !== event.conditions.afterEvent);
  }
  return false;
}

export function getEventsToSend(sentEvents: GTMEvent[], event: Event | EventWithConditions): GTMEvent[] {
  if (shouldEventBeQueued(sentEvents, event)) {
    return [];
  }
  const { conditions, ...eventWithoutConditions } = event;
  return [
    eventWithoutConditions,
    ...queuedEvents
      .filter(queuedEvent => !shouldEventBeQueued([...sentEvents, eventWithoutConditions], queuedEvent))
      .map(({ conditions, ...event }) => event),
  ];
}
