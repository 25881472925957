/* eslint-disable max-lines */
/* eslint-disable import/max-dependencies */
import '@mediahuis/chameleon-reset';
import '@mediahuis/chameleon-theme-wl/fonts.css';

import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ComponentPropsWithRef, ReactElement, useMemo } from 'react';

import {
  ePaperLinkHref,
  ePaperLinkHrefTarget,
  ePaperLinkImage,
  ePaperLinkLabel,
  navigationVersion,
  searchPrefix,
} from '@hubcms/brand';
import '@hubcms/brand/scss/variables.css';
import { EnvProvider } from '@hubcms/data-access-env';
import { ModalProvider } from '@hubcms/data-access-modal';
import { NavigationProvider } from '@hubcms/data-access-navigation';
import { ScenarioProvider } from '@hubcms/data-access-paywall';
import { TrackingDataProvider } from '@hubcms/data-access-tracking';
import { isAudioVisualArticleContext, isTagPage, isTextualArticleContext } from '@hubcms/domain-cook';
import { AuthProvider } from '@hubcms/feature-auth';
import { AsideMenu, Header as Header2 } from '@hubcms/feature-navigation';
import { WeatherIcon } from '@hubcms/feature-weather';
import { EPaperLink, Footer, Header, SubscriptionButton } from '@hubcms/ui-navigation';
import { mapMySectionToNav } from '@hubcms/utils-my-section';
import { activateSection, mapNavColumn, mapNavItem, mapSocialNavItem } from '@hubcms/utils-navigation';

import { AppMeta } from '../components/PageHead/AppMeta';
import { UserMenuWrapper } from '../components/UserMenuWrapper';
import { PageProps } from '../domain/PageProps';

import './global.css';
import './theme-ext.css';

const MORE_LABEL_KEY = 'navigation.more';
const SEARCH_BUTTON_LABEL_KEY = 'navigation.searchlabel';
const SEARCH_PLACEHOLDER_KEY = 'searchlabel.textbox';
const CANCEL_LABEL_KEY = 'text.cancel';

const getSectionHref = data => {
  const determineUrl = () => {
    if (isTagPage(data)) return `${data?.resolution?.section?.href}${data?.resolution?.remainingPath}`;
    if (data?.context?.__typename === 'SectionPage') return data?.resolution?.section?.href;
    return data?.context?.homeSection?.href;
  };

  const url = determineUrl();
  if (url?.includes('localhost')) {
    const { pathname } = new URL(url);
    return pathname;
  }
  return url;
};

const ERROR_PATHS = ['/_error', '/500'];

export default function App({ Component, pageProps }: AppProps<PageProps>): ReactElement {
  const { pathname } = useRouter();
  const isErrorPage = ERROR_PATHS.includes(pathname);
  const sectionParams = pageProps.data?.sectionParams ?? {};
  const moreLabel = sectionParams[MORE_LABEL_KEY] ?? 'More';
  const searchButtonLabel = sectionParams[SEARCH_BUTTON_LABEL_KEY] ?? 'Search';
  const searchPlaceholder = sectionParams[SEARCH_PLACEHOLDER_KEY] ?? 'Search...';
  const cancelLabel = sectionParams[CANCEL_LABEL_KEY] ?? 'Cancel';

  const handleSearch = (searchTerm: string) => {
    window.location.href = `/${searchPrefix}?q=${searchTerm}`;
  };

  const trackingData = useMemo(() => {
    if (
      pageProps.data &&
      'context' in pageProps.data &&
      (isTextualArticleContext(pageProps.data.context) || isAudioVisualArticleContext(pageProps.data.context))
    ) {
      return { pageid: pageProps.data.context.id };
    }

    return {};
  }, [pageProps.data]);

  const headerData: Pick<ComponentPropsWithRef<typeof Header>, 'navigation' | 'todaysDate'> = useMemo(() => {
    const sectionActivator = activateSection(getSectionHref(pageProps.data));

    return {
      navigation: {
        main: (pageProps?.data?.header?.mainNavigation || []).map(mapMySectionToNav).map(mapNavColumn).map(sectionActivator),
        more: (pageProps?.data?.header?.mainNavigationMore || []).map(mapMySectionToNav).map(mapNavColumn).map(sectionActivator),
        servicesStart: (pageProps?.data?.header?.servicesLeft || []).map(mapMySectionToNav).map(mapNavItem),
        servicesEnd: (pageProps?.data?.header?.servicesRight || []).map(mapMySectionToNav).map(mapNavItem),
      },
      todaysDate: pageProps?.data?.todaysDate || '',
      services: pageProps.weatherData ? <WeatherIcon {...pageProps.weatherData} /> : null,
    };
  }, [pageProps]);

  const userMenuItems = useMemo(() => (pageProps?.data?.header?.userMenu || []).map(mapNavItem), [pageProps]);
  const subscriptionMenuItems = useMemo(() => (pageProps?.data?.header?.subscriptionMenu || []).map(mapNavItem), [pageProps]);

  const footerData: ComponentPropsWithRef<typeof Footer> = useMemo(
    () => ({
      copyright: (pageProps?.data?.footer?.copyright || []).map(mapNavItem),
      primaryNavColumns: (pageProps?.data?.footer?.menuItemsRight || []).map(mapNavColumn),
      secondaryNavColumns: (pageProps?.data?.footer?.menuItemsLeft || []).map(mapNavColumn),
      socials: (pageProps?.data?.footer?.socials || []).map(mapSocialNavItem),
    }),
    [pageProps],
  );

  const serviceItems = (pageProps?.data?.header?.servicesLeft ?? [])
    .concat(pageProps?.data?.header?.servicesRight ?? [])
    .map(mapNavItem);
  const topicItems = (pageProps?.data?.header?.mainNavigation ?? []).map(mapNavItem);

  return (
    <EnvProvider value={pageProps.env}>
      <Head>
        <link rel="icon" href="/favicon.svg" type="image/svg+xml" />
        <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        <link rel="manifest" href="/manifest.json" />
      </Head>
      {pageProps.metaData && (
        <AppMeta metaData={pageProps.metaData} titleSuffix={pageProps.data?.sectionParams['boilerplate.brandname']} />
      )}
      <AuthProvider>
        <ScenarioProvider value={pageProps.scenario ?? 'consented'}>
          <ModalProvider>
            <TrackingDataProvider value={trackingData}>
              <NavigationProvider>
                {!isErrorPage && navigationVersion === 'v1' && (
                  <Header
                    moreButtonLabel={moreLabel}
                    searchButtonLabel={searchButtonLabel}
                    searchInputPlaceholder={searchPlaceholder}
                    searchCancelLabel={cancelLabel}
                    onSearchSubmit={handleSearch}
                    inlineStartElements={
                      <EPaperLink
                        href={ePaperLinkHref}
                        image={ePaperLinkImage}
                        label={ePaperLinkLabel}
                        target={ePaperLinkHrefTarget}
                      />
                    }
                    subscriptionButton={<SubscriptionButton subscriptionMenuItems={subscriptionMenuItems} />}
                    userMenuButton={<UserMenuWrapper userMenuItems={userMenuItems} />}
                    {...headerData}
                  />
                )}
                {!isErrorPage && navigationVersion === 'v2' && (
                  <Header2
                    serviceItems={serviceItems}
                    subscriptionItem={subscriptionMenuItems[0]}
                    topicItems={topicItems}
                    topicLogoPosition="left"
                    userMenuItems={userMenuItems}
                  />
                )}

                <main style={{ marginBlockEnd: 'var(--scale-9)' }}>
                  <Component {...pageProps} />
                </main>

                {!isErrorPage && navigationVersion === 'v2' && <AsideMenu />}

                {!isErrorPage && <Footer {...footerData} />}
              </NavigationProvider>
            </TrackingDataProvider>
          </ModalProvider>
        </ScenarioProvider>
      </AuthProvider>
    </EnvProvider>
  );
}
