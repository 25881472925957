/* eslint-disable camelcase */

import type { IDidomiObject, IUserStatus } from '@didomi/react';

import { getDidomiAsync, getDidomiSync } from './get-didomi';
import { getDidomiUserStatus } from './get-didomi-user-status';

type DidomiExternalConsent = Pick<IUserStatus, 'created' | 'purposes' | 'updated' | 'vendors'>;

export async function getDidomiExternalConsentAsync(includedVendors: string[]): Promise<DidomiExternalConsent> {
  const Didomi = await getDidomiAsync();

  return getDidomiExternalConsent(Didomi, includedVendors);
}

export function getDidomiExternalConsentSync(includedVendors: string[]): DidomiExternalConsent | undefined {
  const Didomi = getDidomiSync();

  if (typeof Didomi === 'undefined') {
    return undefined;
  }

  return getDidomiExternalConsent(Didomi, includedVendors);
}

export function getDidomiExternalConsent(Didomi: IDidomiObject, includedVendors: string[]): DidomiExternalConsent {
  const userStatus = getDidomiUserStatus(Didomi);

  return createDidomiExternalConsent(userStatus, includedVendors);
}

export function createDidomiExternalConsent(userStatus: IUserStatus, includedVendors: string[]): DidomiExternalConsent {
  const filterVendors = (vendor: unknown) => typeof vendor === 'string' && includedVendors.includes(vendor);

  return {
    created: userStatus.created,
    purposes: {
      consent: userStatus.purposes.consent,
      legitimate_interest: userStatus.purposes.legitimate_interest,
    },
    updated: userStatus.updated,
    vendors: {
      consent: {
        enabled: userStatus.vendors.consent.enabled.filter(filterVendors),
        disabled: userStatus.vendors.consent.disabled.filter(filterVendors),
      },
      legitimate_interest: {
        enabled: userStatus.vendors.legitimate_interest.enabled.filter(filterVendors),
        disabled: userStatus.vendors.legitimate_interest.disabled.filter(filterVendors),
      },
    },
  };
}
