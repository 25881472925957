import cx from 'classnames';

import styles from './center.module.scss';

type CenterProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  maxInlineSize: string;
};

function Center({ children, className, maxInlineSize, ...props }: CenterProps) {
  return (
    <div className={cx(className, styles.center)} style={{ maxInlineSize }} {...props}>
      {children}
    </div>
  );
}

export default Center;
