import type { NavItem } from '@hubcms/domain-navigation';

import cx from 'classnames';
import { useState } from 'react';

import { NavigationButton, NavigationLogo, NavigationMenu, NavigationUserMenu } from '@hubcms/ui-navigation-2';
import { useScrollListener } from '@hubcms/utils-browser';

import styles from './header.module.scss';

type HeaderMobileProps = {
  subscriptionItem?: NavItem;
  userMenuItems: NavItem[];
};

function HeaderMobile({ subscriptionItem, userMenuItems }: HeaderMobileProps) {
  const [applyScrollClass, setApplyScrollClass] = useState(false);

  useScrollListener((scrollY, prevScrollY) => {
    if (scrollY === 0) {
      setApplyScrollClass(false);
    }
    if (scrollY > 0 && prevScrollY === 0) {
      setApplyScrollClass(true);
    }
  });

  return (
    <div className={styles.headerMobile} data-testid="header-mobile">
      <NavigationMenu size="sm" />

      <span className={cx(styles.centerContainer, { [styles.scroll]: applyScrollClass })}>
        <NavigationLogo size="sm" />
      </span>

      <div className={cx(styles.splitContainer, { [styles.scroll]: applyScrollClass })}>
        <NavigationLogo size="sm" />
        {subscriptionItem && (
          <NavigationButton
            onClick={() => {
              if (subscriptionItem.url) {
                window.location.href = subscriptionItem.url;
              }
            }}
          >
            {subscriptionItem.text}
          </NavigationButton>
        )}
      </div>

      <NavigationUserMenu items={userMenuItems} size="sm" />
    </div>
  );
}

export default HeaderMobile;
