import NavigationIconMenuClose from './NavigationIconMenuClose';
import NavigationIconMenuServices from './NavigationIconMenuServices';

type NavigationIconName = 'menu-close' | 'menu-services';

const navigationIconConfig: Record<NavigationIconName, JSX.Element> = {
  'menu-close': <NavigationIconMenuClose />,
  'menu-services': <NavigationIconMenuServices />,
};

type NavigationIconProps = {
  name: NavigationIconName;
};

function NavigationIcon({ name }: NavigationIconProps) {
  return navigationIconConfig[name];
}

export default NavigationIcon;
