import type { NavItem } from '@hubcms/domain-navigation';
import type { ReactElement } from 'react';

import HeaderDesktopServices from './HeaderDesktopServices';
import HeaderDesktopTopics from './HeaderDesktopTopics';

import styles from './header.module.scss';

type HeaderDesktopProps = {
  logoPosition?: 'center' | 'left';
  salesElement?: ReactElement;
  serviceItems: NavItem[];
  subscriptionItem?: NavItem;
  topicItems: NavItem[];
  userMenuItems: NavItem[];
};

function HeaderDesktop({
  logoPosition,
  salesElement,
  serviceItems,
  subscriptionItem,
  topicItems,
  userMenuItems,
}: HeaderDesktopProps) {
  return (
    <div className={styles.headerDesktop} data-testid="header-desktop">
      <HeaderDesktopServices navigationItems={serviceItems} subscriptionItem={subscriptionItem} userMenuItems={userMenuItems} />
      <HeaderDesktopTopics logoPosition={logoPosition} navigationItems={topicItems} salesElement={salesElement} />
    </div>
  );
}

export default HeaderDesktop;
