import dynamic from 'next/dynamic';
import { ComponentType, PropsWithChildren } from 'react';

import { AuthMethod } from '@hubcms/domain-auth';

const Auth0DataConsumer = dynamic(() => import('./auth0/Auth0Provider').then(m => m.Auth0Provider), {
  loading: () => <>Loading...</>,
});
const OAuthDataConsumer = dynamic(() => import('./oAuth/OAuthProvider').then(m => m.OAuthProvider), {
  loading: () => <>Loading...</>,
});
const CiamAuth0Consumer = dynamic(() => import('./ciamAuth0/CiamAuth0Provider').then(m => m.CiamAuth0Provider), {
  loading: () => <>Loading...</>,
});
const NoAuth = dynamic(() => import('./noAuth/NoAuthProvider').then(m => m.NoAuthProvider), {
  loading: () => <>Loading...</>,
});

export const providers: Record<AuthMethod, ComponentType<PropsWithChildren>> = {
  auth0: Auth0DataConsumer,
  oauth: OAuthDataConsumer,
  ciamAuth0: CiamAuth0Consumer,
  none: NoAuth,
};
