import { MenuEvent } from '@hubcms/domain-tracking';

export type MenuData = Omit<MenuEvent, 'event' | 'eventaction' | 'eventcategory' | 'wallid'>;

export function createMenuEvent(data: MenuData): MenuEvent {
  return {
    event: 'page_event',
    eventaction: 'click',
    eventcategory: 'menu',
    eventlabel: data.eventlabel,
    wallid: null,
  };
}
