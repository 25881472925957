import type { NavItem } from '@hubcms/domain-navigation';

import { authMethod } from '@hubcms/brand';
import { useAuth } from '@hubcms/data-access-auth';
import { useEnv } from '@hubcms/data-access-env';
import { sendTrackingEvent } from '@hubcms/data-access-tracking';
import { createMenuEvent } from '@hubcms/utils-tracking';

import NavigationUserMenuAuthenticated from './NavigationUserMenuAuthenticated';
import NavigationUserMenuUnauthenticated from './NavigationUserMenuUnauthenticated';

function getLoginRedirectUrl(): string {
  return '/auth/login/';
}

function getLogoutRedirectUrl(env: Record<string, string>): string {
  if (authMethod === 'oauth' && env.LOGOUT_BASE_URL && typeof window !== 'undefined') {
    return `${env.LOGOUT_BASE_URL}${encodeURIComponent(window.location.href.toString())}`;
  }

  return '';
}

type NavigationUserMenuProps = {
  items: NavItem[];
  size?: 'sm' | 'lg';
};

function NavigationUserMenu({ items, size = 'lg' }: NavigationUserMenuProps) {
  const { isAuthenticated, user, logout, login } = useAuth();
  const env = useEnv();

  if (authMethod === 'none') {
    return null;
  }

  const handleLogin = async () => {
    await sendTrackingEvent(createMenuEvent({ eventlabel: 'login' }));
    login(getLoginRedirectUrl());
  };

  const handleLogout = async () => {
    await sendTrackingEvent(createMenuEvent({ eventlabel: 'logout' }));
    logout(getLogoutRedirectUrl(env));
  };

  if (isAuthenticated && user) {
    return <NavigationUserMenuAuthenticated items={items} size={size} user={user} onLogout={handleLogout} />;
  }

  return <NavigationUserMenuUnauthenticated size={size} onLogin={handleLogin} />;
}

export default NavigationUserMenu;
