import { useContext } from 'react';

import { NavigationContext } from './context';

export function useNavigation() {
  const navigationContext = useContext(NavigationContext);

  if (!navigationContext) {
    throw new Error('The useNavigation hook can only be used inside a NavigationProvider');
  }

  return navigationContext;
}
