import type { NavItem } from '@hubcms/domain-navigation';

import { LinkText } from '@mediahuis/chameleon-react';

import styles from './navigation-items.module.scss';

type NavigationItemsProps = {
  items: NavItem[];
};

function NavigationItems({ items }: NavigationItemsProps) {
  return (
    <nav className={styles.navigationItems}>
      {items.map(item => (
        <LinkText className={styles.navigationItem} key={item.uniqueId} href={item.url}>
          {item.text}
        </LinkText>
      ))}
    </nav>
  );
}

export default NavigationItems;
