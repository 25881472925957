import { FC, PropsWithChildren } from 'react';
import { authMethod } from '@hubcms/brand';

import { warn } from '@hubcms/utils-monitoring';
import { AuthMethod } from '@hubcms/domain-auth';

import { providers } from './implementations/providers';

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  if (!authMethod) {
    warn('authMethod is not specified - defaulting to noAuth flow');
  }

  const Provider = providers[(authMethod as AuthMethod) || 'none'];

  return <Provider>{children}</Provider>;
};
