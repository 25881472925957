import type { NavItem } from '@hubcms/domain-navigation';

import cx from 'classnames';
import { useState } from 'react';

import { Center } from '@hubcms/ui-center';
import { NavigationButton, NavigationItems, NavigationLogo, NavigationMenu, NavigationUserMenu } from '@hubcms/ui-navigation-2';
import { useScrollListener } from '@hubcms/utils-browser';

import styles from './header.module.scss';

type HeaderDesktopServicesProps = {
  navigationItems: NavItem[];
  subscriptionItem?: NavItem;
  userMenuItems: NavItem[];
};

function HeaderDesktopServices({ navigationItems, subscriptionItem, userMenuItems }: HeaderDesktopServicesProps) {
  const [applyScrollClass, setApplyScrollClass] = useState(false);

  useScrollListener((scrollY, prevScrollY) => {
    if (scrollY === 0) {
      setApplyScrollClass(false);
    }
    if (scrollY > 0 && prevScrollY === 0) {
      setApplyScrollClass(true);
    }
  });

  return (
    <div className={styles.services} data-testid="header-desktop-services">
      <Center className={styles.servicesContent} maxInlineSize="min(var(--grid-wrap-fluid), var(--max-content-width))">
        <div className={styles.servicesLeft}>
          <NavigationMenu />

          <span className={cx(styles.logoContainer, { [styles.scroll]: applyScrollClass })}>
            <div className={styles.divider} />
            <NavigationLogo size="sm" />
          </span>
        </div>

        <div className={styles.servicesRight} data-testid="header-desktop-services-right">
          <span className={cx(styles.itemsContainer, { [styles.scroll]: applyScrollClass })}>
            <NavigationItems items={navigationItems} />
          </span>

          {subscriptionItem && (
            <span className={cx(styles.subscribeContainer, { [styles.scroll]: applyScrollClass })}>
              <NavigationButton
                onClick={() => {
                  if (subscriptionItem.url) {
                    window.location.href = subscriptionItem.url;
                  }
                }}
              >
                {subscriptionItem.text}
              </NavigationButton>
            </span>
          )}

          <NavigationUserMenu items={userMenuItems} />
        </div>
      </Center>
    </div>
  );
}

export default HeaderDesktopServices;
