import { useRef } from 'react';
import { useEventListener } from 'usehooks-ts';

import { useNavigation } from '@hubcms/data-access-navigation';
import { NavigationIcon } from '@hubcms/ui-navigation-2';
import { IconButton } from '@mediahuis/chameleon-react';

import styles from './aside-menu.module.scss';

function AsideMenu() {
  const { isAsideMenuOpen, toggleIsAsideMenuOpen } = useNavigation();

  const documentRef = useRef(typeof document !== 'undefined' ? document : null);

  const keydownEventListener = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && isAsideMenuOpen) {
      toggleIsAsideMenuOpen();
    }
  };

  useEventListener('keydown', keydownEventListener, documentRef);

  if (isAsideMenuOpen) {
    return (
      <div className={styles.asideMenu}>
        <div className={styles.asideMenuContent}>
          <div className={styles.searchBar}>
            <span className={styles.closeButton}>
              <IconButton
                aria-label="Menu"
                data-testid="navigation-menu-close"
                icon={<NavigationIcon name="menu-close" />}
                onClick={toggleIsAsideMenuOpen}
              />
            </span>

            <p>SEARCH</p>
          </div>

          <p>PANEL CONTENT</p>
        </div>
      </div>
    );
  }

  return null;
}

export default AsideMenu;
