import { Button, Dropdown, DropdownItem } from '@mediahuis/chameleon-react';
import getConfig from 'next/config';
import { useEffect, useRef, useState } from 'react';

import { createNavigationItemClickHandler } from '../NavigationItem';
import { UserMenuAuthenticatedButton, UserMenuUnauthenticatedButton } from '../UserMenuButton';
import { UserMenuProps } from './types';

import UserMenuItem from './UserMenuItem';

import styles from './user-menu.module.scss';

function UserMenu({
  isAuthenticated,
  user = { givenName: '', familyName: '' },
  userMenuItems,
  loginLabel,
  logoutLabel,
  loginAction,
  logoutAction,
}: UserMenuProps) {
  const [isClient, setIsClient] = useState(false);
  const buttonRef = useRef<typeof Button>(null);
  const dropdownRef = useRef<typeof Dropdown>(null);
  const toggleUserMenu = () => dropdownRef.current?.toggle();
  const handleUserMenuAuthenticatedButtonClick = createNavigationItemClickHandler(toggleUserMenu);
  const handleUserMenuUnauthenticatedButtonClick = createNavigationItemClickHandler(loginAction);

  const isAuthenticatedUser = isAuthenticated && user !== null;

  const { ZEPHR_SDK_VERSION } = getConfig();
  const isZephrSdkEnabled = ZEPHR_SDK_VERSION === 'v1';

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <>
      {isAuthenticatedUser ? (
        <UserMenuAuthenticatedButton ref={buttonRef} user={user} onClick={handleUserMenuAuthenticatedButtonClick} />
      ) : (
        <UserMenuUnauthenticatedButton ref={buttonRef} label={loginLabel} onClick={handleUserMenuUnauthenticatedButtonClick} />
      )}

      {/* Remove 'isClient' check in https://mediahuis.atlassian.net/browse/CW-2399 */}
      {isClient && (
        <Dropdown
          className={styles.dropDown}
          style={{ zIndex: isZephrSdkEnabled ? 300 : 900 }}
          ref={dropdownRef}
          anchorRef={buttonRef}
          placement="bottom-end"
          data-testid="user-menu"
        >
          {userMenuItems.map(userMenuItem => (
            <UserMenuItem item={userMenuItem} key={userMenuItem.uniqueId} />
          ))}
          <DropdownItem title={logoutLabel} onClick={logoutAction} />
        </Dropdown>
      )}
    </>
  );
}

export default UserMenu;
