function NavigationIconMenuClose() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Menu">
        <g id="Vector">
          <path
            d="M0.75 4.5C0.75 4.08579 1.08579 3.75 1.5 3.75H21.75C22.1642 3.75 22.5 4.08579 22.5 4.5C22.5 4.91421 22.1642 5.25 21.75 5.25H1.5C1.08579 5.25 0.75 4.91421 0.75 4.5Z"
            fill="#1C1C1C"
          />
          <path
            d="M1.5 12C1.5 11.5858 1.83579 11.25 2.25 11.25H6.75C7.16422 11.25 7.5 11.5858 7.5 12C7.5 12.4142 7.16422 12.75 6.75 12.75H2.25C1.83579 12.75 1.5 12.4142 1.5 12Z"
            fill="#1C1C1C"
          />
          <path
            d="M1.5 19.5C1.5 19.0858 1.83579 18.75 2.25 18.75H14.25C14.6642 18.75 15 19.0858 15 19.5C15 19.9142 14.6642 20.25 14.25 20.25H2.25C1.83579 20.25 1.5 19.9142 1.5 19.5Z"
            fill="#1C1C1C"
          />
        </g>
        <path
          id="Vector_2"
          d="M21.7785 9.23222C21.7089 9.15864 21.626 9.10023 21.5348 9.06038C21.4435 9.02052 21.3456 9 21.2467 9C21.1479 9 21.05 9.02052 20.9587 9.06038C20.8675 9.10023 20.7846 9.15864 20.715 9.23222L16.2215 13.9426C16.1513 14.0156 16.0956 14.1024 16.0576 14.1981C16.0196 14.2937 16 14.3964 16 14.5C16 14.6036 16.0196 14.7063 16.0576 14.8019C16.0956 14.8976 16.1513 14.9844 16.2215 15.0574L20.715 19.7678C20.7846 19.8414 20.8675 19.8998 20.9587 19.9396C21.05 19.9795 21.1479 20 21.2467 20C21.3456 20 21.4435 19.9795 21.5348 19.9396C21.626 19.8998 21.7089 19.8414 21.7785 19.7678C21.8487 19.6948 21.9044 19.608 21.9424 19.5123C21.9804 19.4166 22 19.314 22 19.2104C22 19.1067 21.9804 19.0041 21.9424 18.9085C21.9044 18.8128 21.8487 18.726 21.7785 18.653L17.8092 14.5L21.7785 10.347C21.8487 10.274 21.9044 10.1872 21.9424 10.0915C21.9804 9.99587 22 9.89325 22 9.78962C22 9.68598 21.9804 9.58337 21.9424 9.4877C21.9044 9.39203 21.8487 9.3052 21.7785 9.23222Z"
          fill="black"
        />
      </g>
    </svg>
  );
}

export default NavigationIconMenuClose;
