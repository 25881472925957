import { useEffect, useRef } from 'react';

// The useScrollListener is a hook which optimizes the performance of listening to the scroll event
// It uses requestAnimationFrame to make sure it does not block the main thread by debouncing the callback
// The callback parameter returns both the current and previous Y positions of the scroll so it is possible to check the scroll direction

type ScrollCallback = (scrollY: number, prevScrollY: number) => void;

export function useScrollListener(callback: ScrollCallback) {
  const prevScrollY = useRef(0);

  useEffect(() => {
    let requestId = 0;

    const scrollEventListener = () => {
      if (requestId) {
        window.cancelAnimationFrame(requestId);
      }

      requestId = window.requestAnimationFrame(timeStamp => {
        callback(window.scrollY, prevScrollY.current);
        prevScrollY.current = window.scrollY;

        return timeStamp;
      });
    };

    window.addEventListener('scroll', scrollEventListener);

    return () => window.removeEventListener('scroll', scrollEventListener);
  }, [callback]);
}
